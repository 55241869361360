import React from 'react'
import userSignOut from './firebase/userSignOut'

const TopBar = ({ setUserData, userData, setShowSignIn, setRoom }) => {
  const handleSignOut = async () => {
    const signedOut = await userSignOut()
    if (signedOut) {
      setUserData(null)
    } else {
      console.log("Couldn't sign out!")
    }
  }

  return (
    <React.Fragment>
      <div className="top-bar">
        {userData ? (
          <div className="top-bar-content max-width">
            <strong
              className="logo"
              onClick={() => {
                window.history.pushState(null, null, '/')
                setRoom()
              }}
            >
              Chat
            </strong>
            <span>{userData.displayName}</span>
            <button
              className="btn btn-small btn-blue btn-round"
              onClick={() => handleSignOut()}
            >
              Sign out
            </button>
          </div>
        ) : (
          <div className="top-bar-content no-user max-width">
            <strong>Chat</strong>
            <span>You're not signed in</span>
            <button
              className="btn btn-small btn-blue btn-round"
              onClick={() => setShowSignIn(true)}
            >
              Sign in
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default TopBar
