import React, { useEffect, useState } from 'react'

const ChatBubble = ({ author, message, timestamp, isCurrentUser }) => {
  const getTimeStr = () => {
    const now = Date.now()
    const timeBetween = now - timestamp
    const minutesBetween = Math.ceil(timeBetween / (1000 * 60))
    const hoursBetween = Math.ceil(timeBetween / (1000 * 60 * 60))
    const daysBetween = Math.ceil(timeBetween / (1000 * 60 * 60 * 24))

    if (minutesBetween < 2) {
      return 'Now'
    }

    if (minutesBetween < 60) {
      return minutesBetween + 'm ago'
    }

    if (hoursBetween < 24) {
      return hoursBetween + 'h ago'
    }

    return daysBetween + 'd ago'
  }

  const [timeStr, setTimeStr] = useState(getTimeStr())

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeStr(getTimeStr())
    }, 20000)

    return () => clearInterval(interval)
  })

  return (
    <div
      className={`chat-bubble ${isCurrentUser ? 'chat-bubble-current' : ''}`}
    >
      <div>
        <span className="chat-bubble-author">
          {isCurrentUser ? 'You' : author}
        </span>
        <i className="fas fa-circle"></i>
        <span className="chat-bubble-timestamp">{timeStr}</span>
      </div>
      <p className="chat-bubble-message">{message}</p>
    </div>
  )
}

export default ChatBubble
