import { doc, getDoc } from '@firebase/firestore'
import { db } from './config'

const getUserData = async user => {
  const docRef = doc(db, 'users', user.uid)
  const docSnap = await getDoc(docRef)
  const data = docSnap.data()
  const userData = {
    displayName: user.displayName,
    data: data,
    uid: user.uid,
    user: user,
  }

  return userData
}

export default getUserData
