import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import Chat from './chat/Chat'
import SignInModal from './SignInModal'
import getUserData from './firebase/getUserData'
import { auth } from './firebase/config'

const App = () => {
  const [userData, setUserData] = useState(null)
  const [showSignIn, setShowSignIn] = useState(false)
  const [room, setRoom] = useState(window.location.pathname)
  const [joinRoom, setJoinRoom] = useState('')

  useEffect(() => {
    let checkForUser = async () => {
      auth.onAuthStateChanged(async user => {
        if (user) {
          setUserData(await getUserData(user))
        } else {
          setUserData(null)
        }
      })
    }

    checkForUser()
  }, [])

  useEffect(() => {
    if (room.replace('/', '') === '') {
      document.title = 'Join a chat room'
    } else {
      document.title = decodeURI(room.replace('/', '')) + ' - Chat Room'
    }
  }, [room])

  const handlePopstate = () => {
    setRoom(window.location.pathname)
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate)
    return () => window.removeEventListener('popstate', handlePopstate)
  }, [])

  const handleSetUserData = data => {
    setUserData(data)
  }

  const handleJoinRoom = e => {
    e.preventDefault()
    window.history.pushState(null, '', joinRoom)
    setRoom(joinRoom)
  }

  return (
    <React.Fragment>
      <TopBar
        userData={userData ? userData : false}
        setUserData={handleSetUserData}
        setShowSignIn={setShowSignIn}
        setRoom={() => setRoom('')}
      />
      {showSignIn ? (
        <SignInModal
          setUserData={setUserData}
          hideSignIn={() => setShowSignIn(false)}
          closeModal={setShowSignIn}
        />
      ) : null}

      <React.Fragment>
        {userData ? (
          <React.Fragment>
            {room && room !== '/' ? (
              <Chat
                room={decodeURI(room.replace('/', ''))}
                userData={userData}
              />
            ) : (
              <div className="main max-width">
                <h2>Join a chat room</h2>
                <form onSubmit={e => handleJoinRoom(e)}>
                  <input
                    type="text"
                    onChange={e => setJoinRoom(e.target.value)}
                    value={joinRoom}
                  />
                  <button className="btn btn-large btn-blue btn-round">
                    Join
                  </button>
                </form>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className="main max-width">
            <span>Sign in to use chat</span>
            <button
              className="btn btn-large btn-blue btn-round"
              onClick={() => setShowSignIn(true)}
            >
              Sign in
            </button>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

export default App
