import { auth } from './config'
import {
  // browserLocalPersistence,
  createUserWithEmailAndPassword,
  // setPersistence,
} from 'firebase/auth'
import setNewUserData from './setNewUserData'
import getUserData from './getUserData'

const createAccount = async (displayName, email, password) => {
  return await createUserWithEmailAndPassword(auth, email, password)
    .then(async userCredential => {
      const user = userCredential.user
      await setNewUserData(user, displayName)

      return await getUserData(user)
    })
    .catch(() => console.log('Could not create account!'))
}

export default createAccount
