// import { collection, doc, setDoc } from '@firebase/firestore'
import { updateProfile } from 'firebase/auth'
// import { db } from './config'

// const usersRef = collection(db, 'users')

const setNewUserData = async (user, displayName) => {
  await updateProfile(user, { displayName })
}

export default setNewUserData
