import React, { useState } from 'react'
import createAccount from './firebase/createAccount'
import userSignIn from './firebase/userSignIn'

const SignInModal = ({ closeModal, hideSignIn, setUserData }) => {
  const [newUser, setNewUser] = useState(false)
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onGotData = userData => {
    if (userData) {
      setUserData(userData)
      hideSignIn()
    }
  }

  const handleCreateAccount = async e => {
    e.preventDefault()
    const userData = await createAccount(displayName, email, password)
    onGotData(userData)
  }

  const handleSignIn = async e => {
    e.preventDefault()
    const userData = await userSignIn(email, password)
    onGotData(userData)
  }

  const renderedUserType = () => {
    if (newUser) {
      return (
        <div>
          <h3>Create your account</h3>
          <form onSubmit={e => handleCreateAccount(e)}>
            <label htmlFor="name">Display name</label>
            <input
              type="text"
              name="name"
              value={displayName}
              onChange={e => setDisplayName(e.target.value)}
              required
            />
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <button className="btn btn-large btn-blue">Submit</button>
          </form>
          <button
            className="btn btn-icon back"
            onClick={() => setNewUser(false)}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
        </div>
      )
    } else {
      return (
        <div>
          <h3>Sign in</h3>
          <form onSubmit={e => handleSignIn(e)}>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <button className="btn btn-large btn-blue">Submit</button>
          </form>
          <button
            className="btn btn-large btn-blue-outline"
            onClick={() => setNewUser(true)}
          >
            Create an account
          </button>
        </div>
      )
    }
  }

  return (
    <div className="sign-in-modal">
      <div className="sign-in-modal-container">
        {renderedUserType()}
        <button className="btn btn-icon close" onClick={() => closeModal()}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  )
}

export default SignInModal
