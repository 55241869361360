import { auth } from './config'
import {
  signInWithEmailAndPassword,
  browserLocalPersistence,
  setPersistence,
} from 'firebase/auth'
import getUserData from './getUserData'

const userSignIn = async (email, password) => {
  return setPersistence(auth, browserLocalPersistence)
    .then(async () => {
      return await signInWithEmailAndPassword(auth, email, password)
        .then(async userCredential => {
          const user = userCredential.user
          return await getUserData(user)
        })
        .catch(() => console.log('Could not sign in!'))
    })
    .catch(error => console.log(error))
}

export default userSignIn
