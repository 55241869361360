import React, { useEffect, useRef, useState } from 'react'
import ChatBubble from './ChatBubble'
import { db } from '../firebase/config'
import { doc, onSnapshot, getDoc } from '@firebase/firestore'
import { setDoc } from 'firebase/firestore'

const Chat = ({ room, userData }) => {
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const formRef = useRef()

  useEffect(() => {
    const unsub = onSnapshot(doc(db, 'chat-rooms', room), doc => {
      const data = doc.data()
      const messages = []

      if (data) {
        if (data.messages && data.messages.length) {
          data.messages.forEach(message => messages.push(message))

          setMessages(messages)
        }
      } else {
        setMessages(false)
      }

      window.scrollTo(0, document.body.scrollHeight)
    })

    return () => unsub()
  }, [room])

  const sendMessage = e => {
    e.preventDefault()

    if (message) {
      const getData = async () => {
        const docSnap = await getDoc(doc(db, 'chat-rooms', room))
        const data = docSnap.data()
        const messageData = {
          id: Math.random() * 10000 * messages.length,
          author: userData.displayName,
          message: message,
          timestamp: Date.now(),
          authorId: userData.uid,
        }

        console.log(data, room)
        if (data) {
          const newData = {
            messages: data.messages
              ? [...data.messages, messageData]
              : [messageData],
          }
          await setDoc(doc(db, 'chat-rooms', room), newData).then(() =>
            setMessage('')
          )
        } else {
          await setDoc(doc(db, 'chat-rooms', room), {
            messages: [messageData],
          }).then(() => setMessage(''))
        }
      }

      getData()
    }
  }

  const renderedMessages = messages
    ? messages.map(message => {
        return (
          <ChatBubble
            key={message.id}
            author={message.author}
            message={message.message}
            timestamp={message.timestamp}
            isCurrentUser={userData.uid === message.authorId ? true : false}
          />
        )
      })
    : null

  return (
    <div className="chat">
      {room ? (
        <div className="chat-info">
          <div className="chat-info-container max-width">
            <div className="chat-name-container">
              <span>Chat room: </span>
              <span className="chat-name">{room}</span>
            </div>
          </div>
        </div>
      ) : null}
      {messages ? (
        <div className="chat-container max-width">{renderedMessages}</div>
      ) : (
        <div className="main max-width">
          <h2>No messages yet, send the first one!</h2>
        </div>
      )}
      <form ref={formRef} className="chat-input" onSubmit={e => sendMessage(e)}>
        <textarea
          placeholder="Type your message here..."
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              sendMessage(e)
            }
          }}
        />
        <button className="btn btn-small btn-pink">Send</button>
      </form>
    </div>
  )
}

export default Chat
