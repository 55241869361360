// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAI4Qq3q_4rPVF1BZle9x0-S85SAdvQVPs',
  authDomain: 'dezlenko-apps.firebaseapp.com',
  projectId: 'dezlenko-apps',
  storageBucket: 'dezlenko-apps.appspot.com',
  messagingSenderId: '631847916102',
  appId: '1:631847916102:web:45ae58341aa4e7ef9ef047',
  measurementId: 'G-35NCSYHNDW',
}

// Initialize Firebase
initializeApp(firebaseConfig)
export const auth = getAuth()
export const db = getFirestore()
// const analytics = getAnalytics(app)
