import { auth } from './config'
import { signOut } from '@firebase/auth'

const userSignOut = async () => {
  return await signOut(auth)
    .then(() => {
      return true
    })
    .catch(() => false)
}

export default userSignOut
